import React from "react"
import { graphql } from "gatsby"
import { Box, Heading, Text } from "@chakra-ui/core"

import Articles from "./articles"
import { Slices, Layout, SEO } from "../../components"
import { Gallery } from "../slices"

const Subpage = ({ data }) => {
  const doc = data.page
  const articles = data.articles
  // const workshops = data.workshops
  const actors = data.actors

  if (!doc) return null

  // Articles
  if (doc.id === "-65789e5f-2197-54da-9f67-b85e2189925d") {
    return (
      <Layout>
        <SEO seo={doc.seo} />
        <Slices input={doc.content} />
        <Articles input={articles} />
        <Gallery input={actors} />
        {/* <Gallery input={workshops} /> */}
        <Box maxW="5xl" mx="auto" textAlign="center" px="4" my={{ base: "10", xl: "32" }}>
          <Heading as="h3" fontSize={{ base: "4xl", lg: "6xl", xl: "5.625rem" }} pb={{ base: 8, xl: 16 }}>
            Hamburg Konvent <i>Mitreden</i>
          </Heading>
          <Heading pb={{ base: 4, xl: 8 }}>Die Online-Livetalks zur Zukunft der Stadt</Heading>
          <Text mb="4">
            „Tor zur Welt“, „Wachsende Stadt“, „Schönste Stadt der Welt“. Die Etiketten für Deutschlands
            zweitgrößte Metropole täuschen leicht darüber hinweg, dass Städte wie Kopenhagen, Amsterdam,
            München oder Mailand der Hansestadt in Sachen Wissensökonomie, urbaner Innovation. Lebensqualität
            und Internationalität längst den Rang ablaufen.
          </Text>
          <Text mb="4">
            Muss sich die Hafen- und Handelsstadt im Jahrhundert der globalen Verwerfungen neu erfinden? Muss
            sie die Fundamente ihrer Prosperität und damit auch ihrer Lebensqualität neu definieren? Braucht
            die Stadt eine neue Vision, um nicht an den Rand des Geschehens gedrängt zu werden?
          </Text>
          <Text mb="4">
            In sieben <b>„Hamburg Konvent Mitreden - die Online-Livetalks"</b> loten Beobachter*innen und
            „Stadtmacher*innen“ aus, wie es um die Zukunftsfähigkeit Hamburgs steht. Die einstündigen
            Standortbestimmungen sind eine Einladung an die Öffentlichkeit, mitzudiskutieren. Über das
            Internet sind die Menschen aus ganz Hamburg und darüber hinaus eingeladen, ihre Beobachtungen in
            die Diskussion einzubringen.
          </Text>
          <Text>
            Mehr zu Themen und Teilnehmer*innen der Livetalks und wie Sie dabei sein können, erfahren Sie hier
            in den nächsten Wochen.
          </Text>
        </Box>
      </Layout>
    )
  }

  return (
    <Layout>
      <SEO seo={doc.seo} />
      <Slices input={doc.content} />
    </Layout>
  )
}

export default Subpage

export const query = graphql`
  query Page($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      seo {
        title
        description
      }
      id
      title
      slug {
        current
      }
      content {
        ... on SanityOutro {
          _key
          _type
          _rawBlock(resolveReferences: { maxDepth: 10 })
          title
        }
        ... on SanityHero {
          _key
          _type
          animation
          _rawBlock(resolveReferences: { maxDepth: 10 })
          title
        }
        ... on SanityCard {
          _key
          _type
          title
          _rawBlock(resolveReferences: { maxDepth: 10 })
          icon {
            asset {
              url
            }
          }
        }
        ... on SanityBodyBlock {
          _key
          _type
          _rawBlock(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityTheses {
          number
          title
          _type
          _rawBody(resolveReferences: { maxDepth: 10 })
          _id
          _key
        }
        ... on SanityProcess {
          title
          _id
          _type
          _rawBody
          icon {
            asset {
              url
            }
          }
        }
        ... on SanityXingEvent {
          id
          _key
          _type
          url
        }
      }
    }

    articles: allSanityPost(sort: { fields: _createdAt, order: DESC }) {
      nodes {
        title
        _id
        externalLink
        external
        slug {
          current
        }
        intro {
          children {
            text
          }
        }
        mainImage {
          asset {
            fluid(maxHeight: 470) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }

    actors: sanityConversationPartner {
      title
      _rawIntro(resolveReferences: { maxDepth: 10 })
      person {
        _rawBio(resolveReferences: { maxDepth: 10 })
        name
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }

    workshops: sanityWorkshop {
      title
      _rawIntro(resolveReferences: { maxDepth: 10 })
      _type
      direction
      person {
        name
        _rawBio(resolveReferences: { maxDepth: 10 })
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`
