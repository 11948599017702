import React from "react"
import { Link as InternalLink } from "gatsby"
import Img from "gatsby-image"
import { Box, Heading, Text, Grid, Flex, PseudoBox, Link } from "@chakra-ui/core"

import { Button } from "../../components"

const ArticleItem = ({ input, direction }) => {
  if (!input) return null

  return (
    <PseudoBox
      key={input._id}
      as={Flex}
      bg="white"
      boxShadow="8px 8px 0 0 yellow"
      direction="column"
      justifyContent={!input.mainImage ? "center" : "flex-start"}
      position="relative"
      mb={{ base: 8, md: 16, lg: 20 }}
      transition="box-shadow .2s ease"
      _hover={{ boxShadow: "0 0 0 0 yellow" }}>
      {input.mainImage && (
        <Box>
          <Img fluid={{ ...input.mainImage.asset.fluid, aspectRatio: !direction ? 1 : 16 / 9 }} />
        </Box>
      )}
      <Box
        bg="white"
        px={{ base: 0, sm: 4, md: 8 }}
        zIndex="docked"
        py={{ base: 8, xl: 8 }}
        mx={{ base: 4, xl: 8 }}
        mt={input.mainImage ? (direction ? { base: 0, lg: "-20%" } : { base: 0, lg: "-35%" }) : 0}>
        <Heading
          as="h5"
          mb={{ base: "4", xl: "10" }}
          fontWeight="black"
          fontSize={{ base: "2xl", xl: "3xl" }}
          style={{
            hyphens: "auto",
          }}>
          {input.title}
        </Heading>
        <Text>{input.intro[0].children[0].text}</Text>
        {input.slug && !input.external ? (
          <Button as={InternalLink} to={`/diskurs/${input.slug.current}`} mt="8">
            Mehr erfahren
          </Button>
        ) : input.externalLink ? (
          <Button as={Link} href={input.externalLink} target="_blank" rel="noopener noreferrer" mt="8">
            Mehr erfahren
          </Button>
        ) : null}
      </Box>
    </PseudoBox>
  )
}

const Articles = ({ input }) => {
  const articlePairs = input.nodes.reduce((result, value, index, array) => {
    if (index % 2 === 0) {
      result.push(array.slice(index, index + 2))
    }
    return result
  }, [])

  return (
    <>
      <Box bg="background" py={{ base: 8, md: 16, lg: 20 }}>
        <Box maxW="1640px" pl={{ base: 4, lg: 8 }} pr={{ base: 6, lg: 10 }} mx="auto">
          {articlePairs.map((article, index) => {
            const isEven = index % 2 !== 0
            const isOdd = index % 2 !== 1

            return (
              <Grid
                key={index}
                gridTemplateColumns={
                  isOdd
                    ? { base: "1fr", lg: "repeat(2, 1fr)", xl: "1fr 521px" }
                    : { base: "1fr", lg: "repeat(2, 1fr)", xl: "521px 1fr" }
                }
                gap={{ base: 0, lg: 12 }}>
                <ArticleItem input={article[0]} direction={isOdd} />
                <ArticleItem input={article[1]} direction={isEven} />
              </Grid>
            )
          })}
        </Box>
      </Box>
    </>
  )
}

export default Articles
